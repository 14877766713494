/** @jsxImportSource theme-ui */
import { forwardRef } from "react";
import { Flex } from "theme-ui";

type Props = {
  bg?: string;
  children: React.ReactNode;
  className?: string;
  color?: string;
  id?: string;
};

const Section = forwardRef<any, Props>(
  ({ bg = "background", color = "primary", children, id, ...rest }, ref) => {
    return (
      <Flex
        {...rest}
        as="section"
        id={id}
        ref={ref}
        sx={{
          bg,
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          scrollSnapAlign: "start",
          "& ::selection": {
            bg: color,
            color: "white",
          },
        }}
      >
        {children}
      </Flex>
    );
  },
);

export default Section;
