/** @jsxImportSource theme-ui */
import { useRef } from "react";
import { useThemeUI } from "theme-ui";
import { useIntersection } from "react-use";
import { invert } from "polished";
import { transitionTimes } from "../utils/transitions";

type Props = {
  children: React.ReactNode;
  className?: string;
};

const Emphasis = ({ children, ...rest }: Props) => {
  // Invert the primary color to use mix-blend-mode
  const { theme } = useThemeUI();
  const color = theme.colors.primary;
  const invertHex = invert(typeof color === "object" ? color[0] : color);

  // Intersection Observer
  const ref = useRef(null);
  const threshold = 0.5;
  const intersection = useIntersection(ref, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  return (
    <em
      {...rest}
      data-no-cursor
      ref={ref}
      sx={{
        color: "black",
        display: "inline-flex",
        fontStyle: "normal",
        overflow: "hidden",
        position: "relative",
        px: "4px",
        /* Pseudo elements (as per ButtonLink) */
        "&:before, &:after": {
          content: "''",
          display: "block",
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          transform: `translateX(${inView ? 0 : -110}%)`,
          transformOrigin: "left",
          transition: `transform ${transitionTimes.md}ms cubic-bezier(0.85, 0, 0.15, 1) ${transitionTimes.lg}ms`,
          width: "100%",
          willChange: "transform",
          zIndex: -1,
        },
        "&:before": {
          pointerEvents: "none",
          zIndex: 1,
          "@supports (mix-blend-mode: difference)": {
            backgroundColor: "white",
            mixBlendMode: "difference",
          },
        },
        "&:after": {
          bg: "primary",
          "@supports (mix-blend-mode: difference)": {
            bg: invertHex,
          },
        },
      }}
    >
      {children}
    </em>
  );
};

export default Emphasis;
