/** @jsxImportSource theme-ui */
import { useRef } from "react";
import { useIntersection } from "react-use";
import { useSpring, animated as a, config } from "react-spring";
import Emphasis from "./Emphasis";
import Section from "./Section";
import { transitionTimes } from "../utils/transitions";

const Page404 = () => {
  // Intersection Observer
  const ref = useRef(null);
  const threshold = 0.5;
  const intersection = useIntersection(ref, { threshold });
  const inView = intersection ? intersection.intersectionRatio >= threshold : false;

  // Spring
  const { opacity, transform } = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? `translateY(0)` : `translateY(2vh)`,
    config: { ...config.slow, clamp: true },
    delay: inView ? transitionTimes.xs : 0,
  });

  return (
    <Section sx={{ minHeight: 350 }}>
      <a.h1
        ref={ref}
        style={{ opacity, transform }}
        sx={{
          fontSize: ["lg", "xl", "xxl", null, "xxxl"],
          fontWeight: "extraBold",
          lineHeight: "md",
          pl: [2, null, 5, null, 6],
          pr: [2, null, 5],
          willChange: "opacity, transform",
        }}
      >
        💔 Page&nbsp;
        <Emphasis
          sx={{
            fontFamily: "monospace",
            overflowWrap: "anywhere",
          }}
        >
          not_found.
        </Emphasis>
      </a.h1>
    </Section>
  );
};

export default Page404;
