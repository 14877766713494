import Page from "../components/Page";
import Page404 from "../components/Page404";
import SEO from "../components/SEO";

const NotFoundPage = (props: any) => {
  const { transitionStatus, uri } = props;

  return (
    <Page transitionStatus={transitionStatus} uri={uri}>
      <SEO title="Page not found" />
      <Page404 />
    </Page>
  );
};

export default NotFoundPage;
